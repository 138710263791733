module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"899561560804408"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","pt","af","sq","am","ar","hy","az","eu","be","bn","bs","bg","my","ca","ny","zh","co","hr","cs","da","nl","eo","et","fi","fr","gl","ka","de","el","gu","ht","ha","he","hi","hu","id","ga","ig","is","it","ja","jv","kn","kk","km","ky","ko","ku","lb","lo","lt","lv","mk","mg","ms","ml","mt","mi","mr","mn","nb","ne","nn","no","pa","fa","pl","ps","ro","ru","sd","sm","sr","gd","sn","si","sk","sl","so","st","es","su","sw","sv","ta","te","tg","th","tl","tr","uk","ur","uz","vi","cy","fy","xh","yi","yo"],"defaultLanguage":"en","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-96654033-2"},
    }]
